import * as jose from 'jose'
import { environment } from 'configuration'

type AuthOptions = {
  /**
   * A key used to parse the token value from a cookie.
   * @default 'access_token'
   */
  accessTokenKey?: string
  /**
   * A full path representing the endpoint of `trustedIssuerUrl` service that provides the public JSON Web Key Set.
   * @default '/.well-known/jwks.json'
   */
  jwksPath?: string
  /** An URL of the Auth0 service that provides the public JSON Web Key Set and is considered a trusted one. */
  trustedIssuerUrl: string
}

class AuthenticationService {
  private readonly _accessTokenKey
  private _trustedIssuerUrl
  private readonly _jwksPath

  constructor(options: AuthOptions) {
    this._trustedIssuerUrl = options.trustedIssuerUrl
    this._accessTokenKey = options.accessTokenKey ?? 'access_token'
    this._jwksPath = options.jwksPath ?? '/.well-known/jwks.json'
  }

  public set trustedIssuerUrl(value: string) {
    this._trustedIssuerUrl = value
  }

  public parseTokenFromCookie(cookie: string, keyName = this._accessTokenKey): string | null {
    const searchParams = new URLSearchParams(cookie)
    return searchParams.get(keyName)
  }

  public async verifyToken(token: string): ReturnType<typeof jose.jwtVerify> {
    // if the provided issuer differs from the trusted one => throw away the token
    if (!this.isTrustedIssuer(token)) {
      throw new Error('The issuer provided within the token can not be trusted.')
    }

    const jwks = this.getPublicJWKSet()
    return await jose.jwtVerify(token, jwks)
  }

  public decodeToken(token: string): jose.JWTPayload {
    return jose.decodeJwt(token)
  }

  public async isAuthenticated(token: string): Promise<boolean> {
    if (!token) return false

    try {
      await this.verifyToken(token)
      return true
    } catch (error) {
      return false
    }
  }

  /**
   * Checks whether the issuer provided within the token is the one we can trust.
   */
  private isTrustedIssuer(token: string): boolean {
    const { iss } = this.decodeToken(token)

    if (!iss) return false

    return new URL(iss).origin === new URL(this._trustedIssuerUrl).origin
  }

  /**
   * Fetch and cache the public JWK set from the Auth0 service.
   * The default cache is set to 10 minutes.
   * https://github.com/panva/jose/blob/main/docs/jwks/remote/functions/createRemoteJWKSet.md
   */
  private getPublicJWKSet() {
    return jose.createRemoteJWKSet(new URL(`${this._trustedIssuerUrl}${this._jwksPath}`))
  }
}

export default new AuthenticationService({
  trustedIssuerUrl: environment.auth0Url,
})
