import Header, { type HeaderData } from '@christies/header'
import { ShadowDomWrapper } from 'components/ShadowDomWrapper'
import { environment } from 'configuration'
import { languageMapper, SupportedLanguages } from 'configuration/translations'
import { useDataLoader } from 'hooks/useDataLoader'

export type MaximalHeaderProps = {
  onError: (url: string) => void
  language: SupportedLanguages
}

export function MaximalHeader({ onError, language }: Readonly<MaximalHeaderProps>) {
  const data = useDataLoader<HeaderData>({
    onError,
    url: `${environment.afdUrl}/header-footer-content/header?urlFormat=absolute&language=${languageMapper(language)}`,
  })

  return (
    <>
      <ShadowDomWrapper stylesUrl={environment.headerFooterStylesUrl}>
        {data ? <Header headerData={data} useLegacyLanguageSwitcher /> : null}
      </ShadowDomWrapper>
      <div className="chr-header-footer">
        {/* @ts-ignore */}
        <chr-modal-provider></chr-modal-provider>
      </div>
    </>
  )
}
