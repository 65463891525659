import axios from 'axios'
import { useEffect, useState } from 'react'

type Props = {
  url: string
  onError: (url: string) => void
}

export function useDataLoader<T>({ onError, url }: Props) {
  const [data, setData] = useState<T | null>(null)

  useEffect(() => {
    axios
      .get(url, {
        headers: {
          Accept: 'application/vnd.christies.v1+json',
          'Cache-Control': 'no-cache',
        },
      })
      .then((response) => {
        setData(response.data)
      })
      .catch((e) => {
        if (onError) onError(e.message)
      })
    // we need to keep the dependency array empty to avoid loading the Footer/Header data multiple times
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return data
}
