import { MinimalHeader, MinimalHeaderProps } from './MinimalHeader'
import { MaximalHeader, type MaximalHeaderProps } from './MaximalHeader'

export enum HeaderSize {
  Minimal = 'minimal',
  Maximal = 'maximal',
}

interface HeaderProps extends MinimalHeaderProps, MaximalHeaderProps {
  size: HeaderSize
}

export function Header({ size, hideWhenLoggedIn, userLoggedIn, language, onError }: HeaderProps) {
  return (
    <header>
      {size === HeaderSize.Maximal ? (
        <MaximalHeader language={language} onError={onError} />
      ) : (
        <MinimalHeader hideWhenLoggedIn={hideWhenLoggedIn} userLoggedIn={userLoggedIn} />
      )}
    </header>
  )
}
