import { SameSite } from 'infrastructure/isomorphic-cookies'
import environment from './env'

export const LANGUAGE_CONFIG = {
  default: 'en',
  cookieName: 'CurrentLanguage',
  paramName: 'sc_lang',
  cookieOptions: {
    // expires: 365,
    domain: environment.cookieDomain, // environment.thisAppUrl,
    sameSite: SameSite.None,
  },
}

export enum SupportedLanguages {
  EN = 'en',
  ZH = 'zh',
  ZH_CN = 'zh-cn',
}

export const isSupportedLanguage = (language: SupportedLanguages): boolean => {
  if (!language) return false
  return Object.values(SupportedLanguages).includes(language.toLowerCase() as SupportedLanguages)
}

export const legacyLanguageMapper = (lang: SupportedLanguages): number => {
  switch (lang) {
    case SupportedLanguages.EN:
      return 1
    case SupportedLanguages.ZH:
      return 3
    case SupportedLanguages.ZH_CN:
      return 4
    default:
      return 1
  }
}

export const languageMapper = (lang: SupportedLanguages): string => {
  switch (lang) {
    case SupportedLanguages.EN:
      return 'en'
    case SupportedLanguages.ZH:
      return 'zh-hant'
    case SupportedLanguages.ZH_CN:
      return 'zh-hans'
    default:
      return 'en'
  }
}
