import { PropsWithChildren, useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'

type ShadowDomWrapperProps = {
  stylesUrl: string
}

export const ShadowDomWrapper = ({
  children,
  stylesUrl,
}: PropsWithChildren<ShadowDomWrapperProps>) => {
  const shadowHostRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (shadowHostRef.current && !shadowHostRef.current.shadowRoot) {
      const shadowRoot = shadowHostRef.current.attachShadow({ mode: 'open' })

      // Inject the CSS as a <style> tag
      const styleEl = document.createElement('style')
      styleEl.textContent = `@import "${stylesUrl}";`
      shadowRoot.appendChild(styleEl)
    }
  }, [stylesUrl])

  return (
    <div ref={shadowHostRef}>
      {shadowHostRef.current?.shadowRoot &&
        createPortal(children, shadowHostRef.current.shadowRoot)}
    </div>
  )
}
